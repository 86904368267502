import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

let config = require("../../../../framework/src/config");
import { successfulMan } from "../assets";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start

  // Customizable Area End
}
interface S {
  // Customizable Area Start
  usersData: any;
  submission: any;
  course_feedback: any;
  trainerData: any;
  trainer_feedback: any;
  validationError: boolean;
  selectedOption: any;
  selectCourse: any;
  success: boolean;
  trainerName: string;
  courseId: any;
  trainerId: any;
  optionError: boolean;
  feedbackdataData: any;
  message: string;
  placeholder: string;
  optionShow: boolean;
  optionvalue: string;
  resultres: any;
  openModal: boolean;
  modalType: "success" | "warning" | "confirm" | "schedule";
  info: string;
  isLoading: boolean;
  statusCheck: string;
  // Customizable Area End

}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
export default class SchoolFeedbackController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  eventsDataApiCallId: string = "";
  upcomingEventsApiCallId: string = "";
  coursefeedbackApiCallId: any = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      usersData: [],
      submission: [],
      course_feedback: [],
      trainerData: [],
      trainer_feedback: [],
      selectedOption: "",
      selectCourse: [],
      trainerName: "",
      success: false,
      courseId: [],
      trainerId: [],
      validationError: false,
      optionError: false,
      feedbackdataData: [],
      message: "",
      placeholder: "Please Select Course",
      optionShow: false,
      optionvalue: "",
      resultres: null,
      openModal: false,
      statusCheck: "",
      modalType: 'success',
      info: '',
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getCourseFeedbackData();
  }

  handleClose = () => {
    this.setState({ openModal: false, resultres: null });
  };

  handleCancel = () => {
    this.getCourseFeedbackData();
    this.setState({ selectedOption: "", course_feedback: [], selectCourse: [], courseId: [], resultres: null, validationError: false });
  };
  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {

    switch (apiRequestCallId) {
      case this.eventsDataApiCallId:
        if (responseJson) {
          this.setState({ usersData: responseJson }, () => { });
          const courseSelect = this.state.usersData?.courses;
          this.setState({ selectCourse: courseSelect });
          const modifiedresponse = this.state.usersData?.feedback?.course_feedback?.map(
            (d1: any) => ({ answer: null, ...d1 })
          );
          this.setState({ course_feedback: modifiedresponse });
        }
        break;
      case this.upcomingEventsApiCallId:
        if (responseJson) {
          this.setState({ course_feedback: [] }, () => {
            this.setState({ trainerData: responseJson, course_feedback: responseJson.feedback.course_feedback, statusCheck: responseJson.status });
          })
        }
        break;
      case this.coursefeedbackApiCallId:
        this.handleCancel();
        if (responseJson.message == "Thank you") {
          return this.setState({
            openModal: true,
            modalType: 'success',
            message: "We appreciate your valuable feedback",
            resultres: successfulMan,
          });
        }

        else if (
          this.state.courseId.length === 0 &&
          responseJson.message == "Mind mastery course has already been taken"
        ) {
          return this.setState({
            openModal: true,
            modalType: 'warning',
            message: "Feedback is already submitted for the selected course",
          });
        } else {
          this.setState({ openModal: true, modalType: 'warning', message: "Something Went Wrong" });
        }
        break;
    }
  };

  handleClearDropDown = () => {
    this.setState({ selectedOption: "" })
  }

  handleChangeCourse = (event: React.ChangeEvent<{ value: any, name: any }>) => {
    const value = event.target.value;
    const selectedObject = this.state.selectCourse.filter((element: any) => {
      return element.attributes.id === value;
    })
    this.setState({ selectedOption: value, validationError: false });
    this.getCoursesubmission(selectedObject[0].attributes.id)
  }

  handleValidation = () => {
    const { selectedOption } = this.state;
    if (selectedOption === "") {
      this.setState({ validationError: true });
      return false;
    } else {
      this.setState({ validationError: false });
      return true;
    }
  };

  getToken = () => {
    return localStorage.getItem("user_token");
  };

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          this.handleApiResponse(apiRequestCallId, responseJson);
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  goBack = () => {
    this.props.navigation?.goBack();
  };

  async courseapicall(
    diffrentAPICallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    let FullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );

    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      diffrentAPICallId
    );

    try {
      let response = await fetch(FullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body,
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();

      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "Please try again later."
      );
    }
    this.send(apiResponseMessage);
  }

  async getCourseFeedbackData() {
    const header = {
      Authorization: this.getToken(),
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.eventsDataApiCallId = apiRequest.messageId;
    await this.courseapicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.SchoolFeedback.EventsDataApiEndPoint,
      header
    );
  }

  async getCoursesubmission(courseName: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken(),
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.upcomingEventsApiCallId = apiRequest.messageId;
    await this.courseapicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.SchoolFeedback.UpcommingEventsEndPoint}?mind_mastery_course_id=${courseName}`,
      header
    );
  }

  handleAnswerChange = (id: any, answer: any) => {
    if(this.state.statusCheck === "submitted"){
      alert("Already Submitted")
    }else{
    this.setState((prevState) => {
      const updatedCousr = prevState.course_feedback?.map((item: any) => {
        if (item.question_id === id) {
          return { ...item, answer };
        }
        return item;
      });
      return { course_feedback: updatedCousr };
    });
    const idToRemove = id;

    const updatedIds = this.state.courseId.filter(
      (id: any) => id !== idToRemove
    );
    this.setState({ courseId: updatedIds });
    this.handleOptionValidation();
  }
  };

  handleOptionValidation = () => {
    const { course_feedback } = this.state;
    course_feedback?.forEach((fruit: any) => {
      if (fruit.answer == null || fruit.answer == "") {
        this.state.courseId?.push(fruit.question_id);
        this.setState({ optionError: true });
      } else {
        this.setState({ optionError: false });
      }
      return this.state.optionError;
    });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    this.handleValidation();
    this.handleOptionValidation();
    if (this.state.courseId.length !== 0) {
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.programSchedulerContentType,
        Authorization: this.getToken(),
      };
      const body = JSON.stringify({
        mind_mastery_course_id: this.state.selectedOption,
        feedback: {
          course_feedback: this.state.course_feedback,
        },
      });
      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.coursefeedbackApiCallId = apiRequest.messageId;

      await this.courseapicall(
        apiRequest.messageId,
        configJSON.dashboardPostApiMethod,
        configJSON.SchoolFeedback.CourseFeedbackEndPoint,
        header,
        body
      );
    }
    return true;
  };

  OnCreate = async (event: any) => {
    event.preventDefault();
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken(),
    };
    const body = JSON.stringify({
      mind_mastery_course_id: this.state.selectedOption,
      feedback: {
        course_feedback: this.state.course_feedback,
      },
    });
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.coursefeedbackApiCallId = apiRequest.messageId;
    await this.courseapicall(
      apiRequest.messageId,
      configJSON.dashboardPostApiMethod,
      configJSON.SchoolFeedback.CourseFeedbackEndPoint,
      header,
      body
    );
    return true;
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
