import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
let config = require("../../../framework/src/config");
interface OurPartnerLogoData {
  id?: string;
  type?: string;
  attributes?: {
    id?: number;
    image_url?: string;
  };
}

export interface SafetyIQChoice {
  id: number;
  content: string;
  is_correct: boolean;
}

interface SafetyIQQuestion {
  id: number;
  text: string;
  correct_answer: string;
  explanation: string;
  safety_iq_id: number;
  created_at: string;
  updated_at: string;
  question_type: string;
  age: null | string;
  child_age: string;
}

interface SafetyIQAttributes {
  id: number;
  text: string;
  safety_iq_question: SafetyIQQuestion;
  safety_iq_choices: SafetyIQChoice[];
}

export interface SafetyIQ {
  id: string;
  type: string;
  attributes: SafetyIQAttributes;
}

interface AnswerObject {
  choice_id: number;
  question_id: number;
}

interface RequestBody {
  fname: any;
  lname: any;
  email: any;
  school_name?: any;
  age?: any;
  safety_iq_answer?: AnswerObject[];
}

export interface ImpactDetails{
  id: string;
  type: string;
  attributes: {
    id: number,
    value: number,
    name: string,
    image_url: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ourPartnerLogos: OurPartnerLogoData[];
  safetyIQDetails: SafetyIQ[];
  selectedType: string,
  selectAge: string,
  showAlertSuccess?: boolean;
  showAlertFailed?: boolean;
  impactDetails:ImpactDetails[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      ourPartnerLogos: [],
      safetyIQDetails: [],
      selectedType:'',
      selectAge:'',
      showAlertSuccess:false,
      showAlertFailed:false,
      impactDetails:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getPartnerLogo();
    this.getSafetyIQDetails('parents', '3-8');
    this.getImpactDetails()
  }

  getSafetyIQDetails = async (type:string | null = null , age: string | null = null) => {
    if(type){
      this.setState( { selectedType:type })
    }
    if(age){
      this.setState( { selectAge:age })
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    }
    const selectedAge = age?`&age=${age}`: "";
    const safetyIQDetailsRes = await this.landingPageapiCall(
      configJSON.validationApiMethodType,
      `${configJSON.safetyIQDetails}?created_for=${type?type:this.state.selectedType}${selectedAge}`,
      header,
    );
    if (safetyIQDetailsRes) {
      this.setState({ safetyIQDetails: safetyIQDetailsRes });
      return true;
    } else {
      return false;
    }
   
  }

  async getPartnerLogo() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    }
    const res = await this.landingPageapiCall(
      configJSON.validationApiMethodType,
      configJSON.getPartnerLogo,
      header
    );
    if (res) {
      this.setState({ ourPartnerLogos: res });
      return true;
    } else {
      return false;
    }
  }

  submitSection = async (finalAnswers :any) => {
    const header = {
        "Content-Type": configJSON.exampleApiContentType
      }
      const requestBody: RequestBody  = {
        fname: finalAnswers.firstName,
        lname: finalAnswers.lastName,
        email: finalAnswers.email,
      };
      
      if (this.state.selectedType === configJSON.SafetyIQPageContent.SchoolOwners) {
        requestBody.school_name = finalAnswers.schoolName;
      } else {
        requestBody.age = this.state.selectAge;
      }
      
      requestBody.safety_iq_answer = finalAnswers.safety_iq_answer.map((answerObj:AnswerObject) => ({
        choice_id: answerObj.choice_id,
        question_id: answerObj.question_id,
      }));
     
      const res = await this.landingPageapiCall(
        configJSON.exampleAPiMethod,
        'safety_iqs/submit_form',
        header,
        JSON.stringify(requestBody)
      );
 
      if(res){
        this.setState({ showAlertSuccess: true, showAlertFailed: false });
        setTimeout(() => {
          this.setState({ showAlertSuccess: false, showAlertFailed: false });
          location.reload()
        }, 5000);
        return true;
      }else{
        this.setState({showAlertSuccess: false, showAlertFailed: true } );
        setTimeout(() => {
          this.setState({ showAlertSuccess: false, showAlertFailed: false });
        }, 5000);
        return false;
      }

  }

  async landingPageapiCall(method: string, endpoint: string, headers: any, body: any | null = null) {
    let fullURL = endpoint.indexOf("://") === -1 ? config.baseURL + "/" + endpoint : endpoint;
    let responseData: any;
    try {
      if (headers && body) {
        responseData = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers,
          body: body,
        });
      }
      else if (headers) {
        responseData = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers
        });
      }
      let responseJson = await responseData.json();
      if (responseData.status === 200) {
        if(responseJson?.answer?.data){
          return responseJson.answer;
        }else{
          return responseJson.data;
        }
        
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async getImpactDetails() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    }
    const data = await this.landingPageapiCall(
      configJSON.validationApiMethodType,
      configJSON.impactDetails,
      header
    );
    if (data) {
      this.setState({impactDetails:data})
      return true;
    } else {
      return false;
    }
  }
  // Customizable Area End
}
