import React from "react";
// Customizable Area Start
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import MCQLayout from "./MCQLayout.web";
import Alert from "@material-ui/lab/Alert";
import CustomSelect from "./CustomSelect.web";
import { SafetyIQ, SafetyIQChoice } from "../../LandingPageController";
// Customizable Area End
// Customizable Area Start
const validationSchema = (value: number) => (Yup.object({
  firstName: Yup.string().trim()
    .matches(/^(?=.*\S)[A-Za-z\s]+$/, "First Name must contain only letters and spaces and cannot be empty.")
    .required("First Name is required"),
  lastName: Yup.string().trim()
    .matches(/^(?=.*\S)[A-Za-z\s]+$/, "Last Name must be at least 2 letters.")
    .required("Last Name is required"),
  schoolName: value === 3
    ? Yup.string().trim().matches(/^(?=.*\S)[A-Za-z\s]+$/, "School Name must contain only letters and spaces and cannot be empty.").required("School Name is required")
    : Yup.string(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  safety_iq_answer: Yup.array().of(Yup.object().shape({
    choice_id: Yup.string().required("Select the Option!"),
  })),
}));

const useStyles = makeStyles(theme => ({
  card: {
    padding: "32px",
    borderRadius: "10px"
  },
  input: {
    margin: "10px 0"
  },
  heading: {
    textAlign: "center"
  },
  label: {
    fontSize: "20px",
    fontWeight: 500,
    margin: "8px 0",
    fontFamily: "Poppins"
  },
  fieldInput: {
    padding: "15px 10px",
    borderRadius: "5px",
    width: "100%",
    border: "1px solid #0056A3",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#3B3B3B"
  },
  submitBtn: {
    maxWidth: "150px",
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#0056A3",
    padding: "15px 0",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "14px",
    marginTop: "50px",
    "&:hover": {
      color: "white",
      backgroundColor: "#0056A3"
    }
  },
  centerBtn: {
    padding: "20px",
    border: "1px solid #0056A3",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    margin: "10px 0",
    borderRadius: "5px"
  },
  formBox: {
    marginLeft: "64px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      paddingTop: "100px"
    }
  },
  errorMessage: {
    color: "red"
  },
  btnBox: {
    textAlign: "right"
  },
  form: {
    marginTop: "40px"
  },
  selectInput: {
    padding: "15px 10px",
    borderRadius: "5px",
    width: "100%",
    border: "1px solid #0056A3",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#3B3B3B",
    background: "white",
    WebkitAppearance: "none"
  },
  alert: {
    width: "365px"
  },
  safetyIQAlertBox: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px"
  },
  question: {
    marginBottom: theme.spacing(2)
  },
  quest: {
    fontWeight: 600
  }
}));
const GenericQuizForm = (props: any) => {
  const { changeBanner, changeQuestion, safetyIQDetails, formType, submitSection, states}: any = props;
  
  const handleSubmit = (values: any) => {
    submitSection(values);
  };

  const [initialValues, setInitialValues] = React.useState({
    firstName: "",
    lastName: "",
    age: "",
    email: "",
    schoolName: "",
    safety_iq_answer: [] ,
  })

  React.useEffect(() => {
    if(safetyIQDetails){
      const data = safetyIQDetails?.map(({ id  } : { id: any }) => ({ choice_id: "",
        question_id: id  }) );
      setInitialValues({...initialValues, safety_iq_answer: data})
    }
  },[safetyIQDetails])

  const classes = useStyles();
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema(formType)} onSubmit={handleSubmit}>
     {({ values, setFieldValue, errors }) =>  (
    <Form className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box className={classes.input}>
              <Box>
                <label className={classes.label} htmlFor="firstName">
                  First Name*
                </label>
              </Box>
              <Field
                placeholder={"Enter First Name"}
                className={classes.fieldInput}
                type="text"
                id="firstName"
                name="firstName"
              />
              <ErrorMessage
                className={classes.errorMessage}
                name="firstName"
                component={Box}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.input}>
              <Box>
                <label className={classes.label} htmlFor="lastName">
                  Last Name*
                </label>
              </Box>
              <Field
                placeholder={"Enter Last Name"}
                className={classes.fieldInput}
                type="text"
                id="lastName"
                name="lastName"
              />
              <ErrorMessage
                className={classes.errorMessage}
                name="lastName"
                component={Box}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box className={classes.input}>
              <CustomSelect
                changeBanner={changeBanner}
                formType={props.formType}
                changeQuestion={changeQuestion}
                setFieldValue={setFieldValue}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.input}>
              <Box>
                <label className={classes.label} htmlFor="email">
                  Email ID*
                </label>
              </Box>
              <Field
                placeholder={"Enter Email ID"}
                className={classes.fieldInput}
                type="email"
                id="email"
                name="email"
              />
              <ErrorMessage
                className={classes.errorMessage}
                name="email"
                component={Box}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          {props?.safetyIQDetails?.map((safetyIQDetail: SafetyIQ, index: number) => {
            const questionType = safetyIQDetail.attributes?.safety_iq_question.question_type;
            const question = safetyIQDetail.attributes?.safety_iq_question?.text
            if (questionType === 'one_word') {
              return (
                <Box data-test-id='one-word-question' key={index} className={classes.centerBtn}>
                  <Box className={classes.question}>
                    <Typography className={classes.quest}>
                      {question}
                    </Typography>
                  </Box>
                  <Field
                    placeholder="Enter your answer"
                    className={classes.fieldInput}
                    type="text"
                    answer={'8'}
                    id={`${questionType}_index`}
                    name={`${questionType}_index`}
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    name={`safety_iq_answer[${index}].choice_id`}
                    component={Box}
                  />
                </Box>
              )
            }

            if (questionType === 'mcq') {
              const options = safetyIQDetail?.attributes?.safety_iq_choices.map((choice: SafetyIQChoice) => ({ content: choice.content, id: choice.id.toString() }));
              return (
                <Box data-test-id='mcq-question' key={index} className={classes.centerBtn}>
                  <MCQLayout
                    question={safetyIQDetail?.attributes?.safety_iq_question?.text}
                    options={options}
                    answer="answer"
                    handleOption={(answer_id: string) => setFieldValue(`safety_iq_answer[${index}]`, ({
                      choice_id: answer_id,
                      question_id: safetyIQDetail.id
                    }))}
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    name={`safety_iq_answer[${index}].choice_id`}
                    component={Box}
                  />
                </Box>
              );
            }
          })}
        </Grid>
        <Grid item xs={12} md={12}>
          <Box className={classes.btnBox}>
            <Button className={classes.submitBtn} type="submit"  data-test-id="submitForm"  fullWidth>
              Submit
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box className={classes.safetyIQAlertBox}>
            {states.showAlertSuccess && (
              <Alert className={classes.alert} severity="success">
                Form Submitted successfully.
              </Alert>
            )}
            {states.showAlertFailed && (
              <Alert className={classes.alert} severity="error">
                Something went wrong.
              </Alert>
            ) }
          </Box>
        </Grid>
      </Form>
    )}
    </Formik>
  );
};
// Customizable Area End
export default React.memo(GenericQuizForm);
