import React from "react";
// Customizable Area Start
import DashboardController, {
  MindMasteryProps,
  SuperpowerProps,
  ReportProps,
  MpowerProps,
  YouMaterProps,
  OnGoingProgramProps,
} from "./DashboardController";
import SidebarLayoutWrapper from "./SidebarLayout.web";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  LinearProgress,
  LinearProgressProps,
  Link
} from "@material-ui/core";
import { trophyIcon, documentIcon, hourglass, profile, HapStar, HapBeam, HapSpark } from "./assets";
import { makeStyles } from "@material-ui/core/styles";
import RightLayout from "./RightSection.web";
import SchoolDashboard, { CourseNavigation, DashboardCourseCard, InfoBar } from "./SchoolDashboard.web";
import { ProgramModal } from "./ProgramScheduler/ProgramModal.web";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';
import commonStyles from "../../../components/src/commonStyle.web";

const configJSON = require("./config.js");
// Customizable Area End
// Customizable Area Start
interface UserDetails {
  id: number;
  type: string;
  attributes?: {
    role?: string;
    full_name: string;
    image_url: string;
    bio?: any;
  }
}

interface Progress {
  mpower: string;
}

interface MindMastery {
  mindMastery: Array<MindMasteryProps>;
  handleLeftRightNavigation: (type: string) => void;
  assignedCourseIndex: number;
  goTo: (module: string, params?: Object) => void;
}

interface SuperPowers {
  superPowers: Array<SuperpowerProps>;
}

interface Reports {
  reports: Array<ReportProps>;
}
interface UserDetail {
  id: string;
  type: "user_details";
  attributes?: {
    full_name: string;
    bio: string;
    image_url: string;
    role: string;
  };
}
interface Mpower {
  handleRowClick: (isYouMatter: boolean, id: any, school: string, grade: string) => void;
  mpower: Array<MpowerProps>;
}
interface StudentYouMatterType {
  handleRowClick: (isYouMatter: boolean, id: any, school: string, grade: string) => void;
  you_matter: Array<YouMaterProps>;
}
interface OnGoingProgram {
  programs: Array<OnGoingProgramProps>;
  message: string;
  states: any;
  goTo: (module: string, params?: Object) => void;
  handleLeftRightNavigation: (type: string) => void;
}

const webStyles = {
  heading: {
    background:
      "linear-gradient(137.61deg, #0056A3 0%, #09539E 14.18%, #000428 143.69%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
};

const getSuperPowerImage = (name: string) => {
  switch (name) {
    case 'hap_star':
      return HapStar;
    case 'hap_beam':
      return HapBeam;
    case 'hap_spark':
      return HapSpark;
  }
};

const getTextColor = (name: string) => {
  switch (name) {
    case 'hap_star':
      return '#0156A2';
    case 'hap_beam':
      return '#FBBF24';
    case 'hap_spark':
      return '#EF4444';
  }
};
// Customizable Area End
const useStyles = makeStyles(_theme => ({
  // Customizable Area Start
  center: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 2
  },
  courseDescription: {
    color: '#3B3B3B',
    fontFamily: 'Poppins',
    fontSize: '14px',

    fontWeight: 400,
  },
  leftRightBtn: {
    "&.MuiButton-root": {
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      backgroundColor: '#0056A3',
      minWidth: 'auto'
    },
    '& .MuiButton-label': {
      color: 'white',
      fontFamily: "Poppins",
    },
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: '#0056a380'
    }
  },
  leftRightActionBtnWrapper: {
    display: 'flex',
    width: '80px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  userDetailsContainer: {
    background: "#FDFDFD",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 210,
    padding: '15px',
    marginRight: '16px',
    border: "0.2px solid #e3e3e3",
    width: '30%',
    '@media(max-width:767px)': {
      width: '50%'
    }
  },
  profilePhoto: {
    borderRadius: "100%",
    height: 120,
    width: 120,
    '@media(max-width:767px)': {
      width: 100,
      height: 100
    },
    '@media(max-width:400px)': {
      width: 90,
      height: 90
    }
  },
  userName: {
    ...webStyles.heading,
    paddingTop: 24,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: 'center',
    '@media(max-width:767px)': {
      fontSize: '18px'
    }
  },
  userProgressContainer: {
    background: "#FDFDFD",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 210,
    padding: '15px',
    border: "0.2px solid #e3e3e3",
    width: '70%',
    '@media(max-width:825px)': {
      flexDirection: 'column',
      justifyContent: 'center'
    },
    '@media(max-width:767px)': {
      width: '50%',
    }
  },
  trophyIcon: {
    width: '50%',
    marginRight: '20px',
    "@media(min-width:1440px)": {
      width: 'auto'
    },
    '@media(max-width:825px)': {
      width: "160px",
      height: 'auto',
      marginRight: 0
    },
    '@media(max-width:767px)': {
      width: "136px",
    },
    '@media(max-width:400px)': {
      width: "100px",
    }
  },
  progressIndicatorContainer: {
    alignSelf: "flex-start",
    marginTop: 24,
    width: '100%',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#3B3B3B",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    '@media(max-width:825px)': {
      marginTop: 0
    }
  },
  contentContainer: {
    background: "#FDFDFD",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    border: "0.2px solid #e3e3e3",
    padding: 24,
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  contentHeading: {
    ...webStyles.heading,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
    '@media(max-width:767px)': {
      fontSize: '20px'
    }
  },
  contentTitle: {
    color: '#0156A2',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkitLineClamp': 1,
    '-webkitBoxOrient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    '&:hover': {
      '-webkitBoxOrient': 'inherit',
      wordBreak: 'break-word',
    }
  },
  contentProfileContainer: {
    background: "#FDFDFD",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll",
    marginBottom: 20,
    padding: 24,
    "&::-webkit-scrollbar": {
      display: "none",
    }
  },

  contentBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    columnGap: 16,
  },
  cardContainer: {
    minWidth: 224,
    maxWidth: 224,
    minHeight: 345,
    maxHeight: 346,
    borderRadius: 8,
    background: "#FAFAFA",
    boxShadow: "2px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  reportHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#FFFFFF",
    backgroundColor: "#0156A2",
    minHeight: 24,
    marginTop: 16,
    padding: 8,
    borderRadius: "8px",
  },
  reportRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 24,
    padding: 8,
    borderRadius: "8px",
    boxShadow: "2px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    marginTop: 10,
    border: '0.2px solid #e3e3e3'
  },
  reportIcon: {
    height: 20,
  },
  prfImgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px'
  },
  role: {
    color: '#3b3b3b80',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  prfImg: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    border: "0.10px solid rgba(0, 0, 0, 0.12)",
  },
  MainContainerOnGoing: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  onGoingContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  onGoingContentContainer: {
    display: "flex",
    gap: "10px",
  },
  onGoingIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  onGoingIcon: {
    height: "32px"
  },
  onGoingGrade: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    border: "0.10px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    padding: "10px",
    fontWeight: 600,
    boxShadow: " 0px 4px 16px rgba(0, 0, 0, 0.12)",
  },
  onGoingMin: {
    marginLeft: "auto",
  },
  onGoingDuration: {
    display: "flex",
    width: "100%",
    padding: "10px",
    border: "0.10px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    boxShadow: " 0px 4px 16px rgba(0, 0, 0, 0.12)",
  },
  btnContainer: {
    textAlign: "right",
  },
  btn: {
    background: "#0156A2",
    color: "#ffff",
    textTransform: "capitalize",
  },
  td: {
    border: " 0.10px rgba(0, 0, 0, 0.12) solid",
    background: "#ffff",
    borderRadius: "8px",
    boxShadow: " 0px 4px 16px rgba(0, 0, 0, 0.12)",
    fontSize: "14px",
    flexWrap: "nowrap"
  },
  th: {
    border: "0.10px solid rgba(0, 0, 0, 0.12)",
    background: "#0056A3",
    borderRadius: "8px",
    color: "white",
  },
  dashboardPageWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: "#FFFFFF",
    boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
    borderRadius: "12px",
    padding: 24,
    '@media(max-width:960px)': {
      flexDirection: 'column',
    }
  },
  dashboardLeft: {
    width: '68%',
    '@media(max-width:960px)': {
      width: '100%'
    }
  },
  schoolParentLeftContent: {
    width: '68%',
    '@media(max-width:960px)': {
      width: '100%',
      marginBottom: '24px'
    }
  },
  studentTopWrapper: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    '@media(max-width:960px)': {
      // flexDirection: 'column'
    }
  },
  courseImgWrapper: {
    '@media(max-width:767px)': {
      marginRight: "0"
    },
    marginRight: '20px'
  },
  courseImg: {
    '@media(max-width:767px)': {
      marginBottom: '20px',
      width: '100%'
    },
    width: '260px',
    borderRadius: '8px',
  },
  tableWrapper: {
    marginBottom: '20px',
    maxHeight:"250px",
    maxWidth: 'calc(100vw - 175px)',
    '@media(max-width:600px)': {
      maxWidth: 'calc(100vw - 131px)',
    },
    overflowX: 'auto'
  },
  superpowerTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  superPowerLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center'
  },
  superPowerCount: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center'
  },
  superPowerPaper: {
    padding: '10px',
    display: 'flex',
    gap: 25,
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: 'none'
  },
  superPowerImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 180
  },
  superPowerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
  // Customizable Area End
}));
// Customizable Area Start
export const UserDetailsContainer = (props: UserDetails) => {
  const classes = useStyles();
  const Attributes = props.attributes;
  return (
    <Paper className={classes.userDetailsContainer} data-test-id='paper'>
      <img src={Attributes?.image_url || profile} className={classes.profilePhoto} />
      <Typography className={classes.userName}>
        {Attributes?.full_name}
      </Typography>
    </Paper>
  );
};

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{
        `${(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const UserProgressContainer = (props: Progress) => {
  const classes = useStyles();
  return (
    <Paper className={classes.userProgressContainer} data-test-id='paper'>
      <img src={trophyIcon} className={classes.trophyIcon} />
      <Box>
        <Typography className={classes.userName}>{configJSON.Dashboard.MyProgress}</Typography>
        <Box className={classes.progressIndicatorContainer}>
          <Typography>{configJSON.Dashboard.MPower}</Typography>
          {props.mpower && <LinearProgressWithLabel value={Number(props.mpower.split("%")[0])} />}
        </Box>
      </Box>
    </Paper>
  );
};

export const MindMasteryContainer = (props: MindMastery) => {
  const classes = useStyles();
  const { assignedCourseIndex, handleLeftRightNavigation } = props;
  const isDataExist = props?.mindMastery?.length > 0;
  const course = props?.mindMastery;
  let isLeftDisabled = props.assignedCourseIndex === 0 || course.length === 0;
  let isRightDisabled = props.assignedCourseIndex === course.length - 1 || course.length === 0;
  return (
    <Paper className={classes.contentContainer} data-test-id='paper'>
      <CourseNavigation
        mb={'16px'}
        title={configJSON.Dashboard.AssignedCourse}
        isLeftDisabled={isLeftDisabled}
        isRightDisabled={isRightDisabled}
        handleLeftRightNavigation={handleLeftRightNavigation}
      />
      <Box>
        {isDataExist ? (
          <DashboardCourseCard data-test-id='dashboard-course-card' onClick={() => props.goTo(configJSON.MindMasteryTexts.CourseDetailsPage, { courseId: course[assignedCourseIndex].id })} mb={'0'} key={course[assignedCourseIndex].id} courseThumbnail={course[assignedCourseIndex].attributes.thumbnail} courseTitle={course[assignedCourseIndex].attributes.title} courseDescription={course[assignedCourseIndex].attributes.description} />
        ) : (
          <Typography>No Mind Mastery Course Found</Typography>
        )}
      </Box>
    </Paper>
  );
};

export const SuperPowerContainer = (props: SuperPowers) => {
  const classes = useStyles();
  const superPowers = props.superPowers.filter((superPower: SuperpowerProps) => superPower.attributes.count > 0);
  return (
    <Paper className={classes.contentContainer} data-test-id='paper'>
      <Typography className={classes.contentHeading}>{configJSON.Dashboard.SuperpowersCollected}</Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {superPowers && superPowers.length > 0 ? (
          superPowers.map((superPower: SuperpowerProps) => {
            const count = superPower.attributes.count;
            const image = getSuperPowerImage(superPower.attributes.name);
            const title = superPower.attributes.title.replace(" ", "-");
            let textColor = getTextColor(superPower.attributes.name);

            return (
              <Grid key={superPower.id} item xs={12} sm={6} md={4} data-test-id="super-power">
                <Paper className={classes.superPowerPaper}>
                  <Box className={classes.superPowerImageWrapper}>
                    <img className={classes.superPowerImage} src={image} alt={superPower.attributes.name} />
                  </Box>
                  <Box className={classes.superpowerTextWrapper}>
                    <Typography className={classes.superPowerLabel} style={{ color: textColor }}>{title}</Typography>
                    <Typography className={classes.superPowerCount} style={{ color: textColor }}>{count}</Typography>
                  </Box>
                </Paper>
              </Grid>
            )
          })
        ) : (
          <Paper data-test-id="super-power-empty" className={classes.superPowerPaper} style={{ textAlign: 'left', width: '100%' }}>
            <Typography style={{ marginTop: '16px' }}>
              {configJSON.Dashboard.CompleteCourseToCollectSuperPower}
            </Typography>
          </Paper>
        )}
      </Grid>
    </Paper>
  );
};

export const Program = (props: OnGoingProgram) => {
  const classes = useStyles();
  let isLeftDisabled = true
  let isRightDisabled = true
  if (props.programs) {
    isLeftDisabled = props?.states.ongoingProgramIndex === 0 || props?.programs.length === 0;
    isRightDisabled = props?.states.ongoingProgramIndex === props?.programs.length - 1 || props?.programs.length === 0;
  }

  const courseDescription = props.programs && props?.programs[props.states.ongoingProgramIndex]?.attributes?.description
  return (
    <Paper className={classes.contentProfileContainer} data-test-id='box' >
      <div className={classes.MainContainerOnGoing}>
        <Box className={classes.contentBox}>
          <Box className={classes.onGoingContainer}>
            <Typography className={classes.contentHeading}>
              {configJSON.Dashboard.OngoingProgram}
            </Typography>
            <Box className={classes.leftRightActionBtnWrapper}>
              <Button className={classes.leftRightBtn} data-test-id='left' disabled={isLeftDisabled} onClick={() => props.handleLeftRightNavigation('dec')} > <ArrowBackIosRounded /></Button>
              <Button className={classes.leftRightBtn} data-test-id='right' disabled={isRightDisabled} onClick={() => props.handleLeftRightNavigation('inc')}> <ArrowForwardIosRounded /> </Button>
            </Box>
          </Box>
        </Box>

        {props?.programs && props?.programs?.length > 0 ? (
          <>
            <DashboardCourseCard
              data-test-id='dashboard-course-card'
              onClick={() => props.goTo(configJSON.Dashboard.TrainerMaterial)}
              mb="0"
              courseThumbnail={props?.programs[props.states.ongoingProgramIndex]?.attributes?.thumbnail_url}
              courseTitle={props?.programs[props.states.ongoingProgramIndex]?.attributes?.course_name}
              courseDescription={courseDescription}
            />
            <InfoBar
              infoTitle={props?.programs[props.states.ongoingProgramIndex]?.attributes?.school_name}
              city={props?.programs[props.states.ongoingProgramIndex]?.attributes?.city}
              value={`Section: ${props?.programs[props.states.ongoingProgramIndex]?.attributes?.grade}`}
            />
            <InfoBar
              icon={hourglass}
              infoTitle={configJSON.Dashboard.ProgramDuration}
              value={`${props?.programs[props.states.ongoingProgramIndex]?.attributes?.duration} mins`}
            />
          </>
        ) : (
          <Typography>{props?.message}</Typography>
        )}
      </div>
    </Paper>
  );
};

export const ProfileStatus = (props: UserDetail) => {
  const classes = useStyles();
  const user = JSON.parse(`${localStorage.getItem(configJSON.Dashboard.UserDetails)}`)
  const profilePhoto = user?.attributes?.image_url ? user?.attributes?.image_url : profile;
  return (
    <Paper className={classes.contentProfileContainer} data-test-id='paper'>
      <Grid style={{ marginLeft: 4 }} xs={12} alignItems={"center"} className={classes.contentBox}>
        <Box >
          <Box className={classes.prfImgContainer}>
            <img className={classes.profilePhoto} src={profilePhoto} style={{ marginBottom: '10px' }} alt="Profile Picture" />{" "}
            <Typography className={classes.role}                    >
              {props?.attributes?.role}
            </Typography>
          </Box>
        </Box>
        <Box style={{ marginLeft: 10 }}>
          <Typography className={classes.contentHeading}>
            Hello, {props?.attributes?.full_name}
          </Typography>
          <Typography component={"span"}>{props?.attributes?.bio}</Typography>
        </Box>
      </Grid>
    </Paper>
  );
};

export const StudentYouMatter = (props: StudentYouMatterType) => {
  const classes = useStyles();
  const commonClasses = commonStyles({});
  return (
    <Paper className={classes.contentProfileContainer} data-test-id='paper'>
      <Typography className={classes.contentHeading} style={{ marginBottom: '20px' }}>
        {configJSON.Dashboard.StudentsYouMatter}
      </Typography>
      <Box className={classes.tableWrapper}>
        <Grid container item className={commonClasses.tableHead}>
          <Grid xs={true} item className={commonClasses.cell} >{configJSON.Dashboard.YouMatterTitle}</Grid>
          <Grid xs={true} item className={commonClasses.cell} >{configJSON.Dashboard.CourseName}</Grid>
          <Grid xs={true} item className={commonClasses.cell} >{configJSON.Dashboard.DueDate}</Grid>
          <Grid xs={true} item className={commonClasses.cell} >{configJSON.Dashboard.SchoolName}</Grid>
          <Grid xs={true} item className={commonClasses.cell} >{configJSON.Dashboard.Grade}</Grid>
        </Grid>
        {props?.you_matter?.map((val: YouMaterProps) => {
          return (
            <Grid container className={commonClasses.tableRow} data-test-id='row' onClick={() => props.handleRowClick(true, val.id, val.attributes.school, val.attributes.grade)} >
              <Grid xs={true} item className={commonClasses.cell} >{val?.attributes?.name}</Grid>
              <Grid xs={true} item className={commonClasses.cell} >
                {val?.attributes?.course_name}
              </Grid>
              <Grid xs={true} item className={commonClasses.cell} >{val?.attributes?.due_date}</Grid>
              <Grid xs={true} item className={commonClasses.cell} >{val?.attributes?.school}</Grid>
              <Grid xs={true} item className={commonClasses.cell} >{val?.attributes?.grade}</Grid>
            </Grid>
          );
        })}
      </Box>
      <Box className={classes.btnContainer}>
        <Link href={configJSON.Dashboard.TrainerYouMatterPath}>
          <Button className={commonClasses.primaryButton} >
            {configJSON.Dashboard.CreateYouMatter}
          </Button>
        </Link>
      </Box>
    </Paper>
  );
};

export const ReportContainer = (props: Reports) => {
  const classes = useStyles();
  return (
    <Paper style={{ marginBottom: 24 }} className={classes.contentContainer} data-test-id='paper'>
      <Typography className={classes.contentHeading}>{configJSON.Dashboard.Report}</Typography>
      <Box>
        <Box className={classes.reportHeader}>
          <Typography>{configJSON.Dashboard.CourseName}</Typography>
          <Typography>{configJSON.Dashboard.Action}</Typography>
        </Box>
        {props?.reports?.length > 0 ? (
          props?.reports?.map((r: ReportProps) => {
            return (
              <Box key={r.id} className={classes.reportRow}>
                <Typography>{r.attributes.mind_mastery_course.name}</Typography>
                <a href={r.attributes.pdf_url} target="_blank">
                  <img className={classes.reportIcon} src={documentIcon} />
                </a>
              </Box>
            );
          })
        ) : (
          <Typography style={{ marginLeft: 3, marginTop: 16 }}>No Reports Found</Typography>
        )}
      </Box>
    </Paper>
  );
};

export const DashBoardPage = (props: any) => {
  const { role, states, parentProps, handleLeftRightNavigation } = props;
  const classes = useStyles();
  return <Box className={classes.dashboardPageWrapper} data-test-id='box' >
    {role === configJSON.UserRole.Student && (
      <Box className={classes.dashboardLeft}>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Box className={classes.studentTopWrapper}>
              <UserDetailsContainer {...states.userDetail} />
              <UserProgressContainer {...states.progress} />
            </Box>
          </Grid>
          <Grid item xs={12} >
            <MindMasteryContainer goTo={props.goTo} assignedCourseIndex={states.ongoingProgramIndex} handleLeftRightNavigation={handleLeftRightNavigation} mindMastery={states.mindMastery} />
          </Grid>
          <Grid item xs={12} >
            <SuperPowerContainer data-test-id="super-power-container" superPowers={states.superPowers} />
          </Grid>
          <Grid item xs={12} >
            <ReportContainer reports={states.reports} />
          </Grid>
        </Grid>
      </Box>
    )}
    {role === configJSON.UserRole.Trainer && (
      <Box className={classes.dashboardLeft}>
        <Grid container>
          <Grid item xs={12}>
            <ProfileStatus {...states.userDetail} />
          </Grid>
          <Grid item xs={12}>
            <Program goTo={props.goTo} handleLeftRightNavigation={handleLeftRightNavigation}
              states={states}   {...states.onGoingProgram} />
          </Grid>
          <Grid item xs={12}>
            <StudentYouMatter handleRowClick={props.handleRowClick} {...states.youMatter} />
          </Grid>
        </Grid>
      </Box>
    )}
    {(role === configJSON.UserRole.School || role === configJSON.UserRole.Parents) && <Box className={classes.schoolParentLeftContent}> <SchoolDashboard {...parentProps} /> </Box>}
    <RightLayout
      openModal={props.openModal}
      isDashboard
      listView={false}
      upcomingEventsList={states.upcomingEvents}
      currentDate={states.currentDate}
      handleDateChange={props.handleDateChange}
    />
  </Box>
}
// Customizable Area End
export default class Dashboard extends DashboardController {
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const role = this.getUserRole();

    if (role === configJSON.UserRole.LowerStudent) {
      this.getMindMastery();
      this.getUserProgress();
      this.getUserReports();
      this.getUserSuperPowers();
      
    } else if (role === configJSON.UserRole.LowerTrainer) {
      this.getMPower();
      this.getaYouMatter();
      this.getOnGoingPrograms();
    }
    this.getUserDetail();
    this.getUpcomingEvents();
  }

  render(): React.ReactNode {
    const user = JSON.parse(`${localStorage.getItem(configJSON.Dashboard.UserDetails)}`)
    const role = user?.attributes?.role;
    return (
      <>
        <SidebarLayoutWrapper {...this.props} {...this.state}>
          <ProgramModal
            isDashboard
            key={this.state.selectedEvent.id}
            selectedEvent={this.state.selectedEvent}
            open={this.state.showPopup}
            handleCancel={this.switchPopup}
            changeColor={() => { }}
            openDeleteModal={() => { }}
            openEditModal={() => { }}
            toggleGuestView={() => { }}
          />
          <DashBoardPage data-test-id='dashboard' handleRowClick={this.handleRowClick} goTo={this.goTo} role={role} parentProps={this.props} states={this.state} handleLeftRightNavigation={this.handleLeftRightNavigation} openModal={this.togglePopup} 
          handleDateChange={this.handleDateChange}/>
        </SidebarLayoutWrapper>
      </>
    );
  }
  // Customizable Area End
}
