import { makeStyles, Theme } from "@material-ui/core/styles";

interface CommonStylesProps {
    labelFontSize?: string;
    labelFontWeight?: number;
    isTitleThere?: boolean;
}

const commonStyles = makeStyles<Theme, CommonStylesProps>((theme) => ({
    primaryButton: {
        "&.MuiButton-root": {
            height: '48px',
            width: '175px',
            borderRadius: "8px",
            backgroundColor: "#0056A3",
            '@media(max-width:575px)': {
                width: '100px'
            }
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#0056a380'
        }
    },
    secondaryButton: {
        "&.MuiButton-root": {
            height: '48px',
            width: '175px',
            borderRadius: "8px",
            border: '1px solid #0056A3',
            backgroundColor: "white",
            display: 'flex',
            '@media(max-width:575px)': {
                width: '100px'
            }
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "#0056A3",
            fontFamily: "Poppins",
            '& img': {
                marginRight: '10px'
            }
        },
        '&.MuiButton-root.Mui-disabled': {
            border: '1px solid gray',
            backgroundColor: '#e3e3e3'
        }
    },
    textArea: {
        '&.MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiInputBase-input': {
            padding: '24px 22px',
            background: '#FDFDFD',
            border: '0.2px solid #0056A3',
            borderRadius: '8px'
        },
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    },
    fieldLabel: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        marginBottom: '7px',
        [theme.breakpoints.up("sm")]: {
            fontSize: (props:{labelFontSize:string}) => props.labelFontSize ? props.labelFontSize : '20px',
            fontWeight: (props:{labelFontWeight:number|string}) => props.labelFontWeight ? props.labelFontWeight : 600,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            fontWeight: (props:{labelFontWeight:number|string}) => props.labelFontWeight ? props.labelFontWeight : 600,
        }
    },
    mainTitle: {
        marginBottom: "10px",
        color: "#0056A3",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "30px",
        '@media(max-width:767px)': {
            fontSize: "20px",
        }
    },
    tableWrapper: {
        marginBottom: '36px',
        maxWidth: 'calc(100vw - 127px)',
        '@media(max-width:600px)': {
            maxWidth: 'calc(100vw - 84px)',
        },
        overflowX: 'auto'
    },
    tableHead: {
        '&.MuiGrid-container': {
            background: '#0056A3',
            borderRadius: '8px',
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '27px',
            justifyContent: 'space-between',
            padding: '15px 24px',
            marginBottom: '16px',
            '@media(max-width:960px)': {
                minWidth: '767px'
            },
        }
    },
    tableRow: {
        '&.MuiGrid-container': {
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
            borderRadius: '8px',
            border: '1px solid #e3e3e3',
            justifyContent: 'space-between',
            marginBottom: '8px',
            padding: '12px 24px',
            cursor:'pointer',
            '@media(max-width:960px)': {
                minWidth: '767px'
            },
        }
    },
    cell: {
        '&.MuiGrid-item': {
            padding: '0 10px',
            margin: 'auto',
            fontSize: '15px',
        }
    },
    iconButton: {
        '&.MuiIconButton-root': {
            padding: "0",
            marginRight: '12px'
        }
    },
    successImg: {
        marginBottom: '40px',
        width: props => props.isTitleThere ? '230px' : '300px',
        '@media(max-width:575px)': {
            width: '60%'
        }
    },
    warningConfirmImg: {
        marginBottom: '40px',
        width: '200px',
        '@media(max-width:575px)': {
            width: '60%'
        }
    },
    successMsg: {
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: '36px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        textAlign: 'center',
        marginBottom: '30px',
        '@media(max-width:767px)': {
            fontSize: "20px",
            lineHeight: '26px',
        },
        '@media(max-width:575px)': {
            fontSize: "16px",
            lineHeight: '20px',
        }
    },
    backdrop: {
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(8, 77, 149, 0.481206)'
        }
    },
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    dialog: {
        '& .MuiPaper-root': {
            padding: '24px',
            color: '#0056A3',
            backgroundColor: '#F0F4F6',
            fontFamily: "Poppins",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '12px',
            '@media(max-width:575px)': {
                padding: '12px 24px 24px 24px',
            }
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '715px',
            width: '100%'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: 'none'
        }
    },
    dialogTitle: {
        textAlign: 'center',
        marginBottom: '15px'
    },
    dialogTitleText: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '48px',
        '@media(max-width:575px)': {
            fontSize: '22px',
            lineHeight: '26px',
        }
    },
    blueHeader: {
        borderRadius: '8px',
        background: '#0056A3',
        color: 'white',
        padding: '14px 20px',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
    }
}))

export default commonStyles;