import { BlockComponent } from "../../../../../framework/src/BlockComponent";

// Customizable Area Start
const navigation = require("react-navigation");
interface DocDetailsType {
    uri: string,
    fileType: string,
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  pdfUrl: string | null;
  modifiedPdfUrl: string | undefined;
  numPages: number | undefined;
  docDetails: Array<DocDetailsType>;
  fileType: string;
  // Customizable Area End
}
interface SS { 
  // Customizable Area Start
  
  // Customizable Area End
}

export default class MaterialDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      pdfUrl: "",
      modifiedPdfUrl: "",
      numPages: 0,
      docDetails:[],
      fileType : ""
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const pdfUrl = urlParams.get("pdfUrl");
    if(pdfUrl){
    this.setState({
      pdfUrl
    }, () => {
      this.handleModifiedPdfUrl();
    });
      this.setDocsArray(pdfUrl);
    }
    document.addEventListener('contextmenu', this.disableContextMenu);
    document.addEventListener('keydown', this.disablePrintAndDownload);
  };

  async componentWillUnmount() {
    document.removeEventListener('contextmenu', this.disableContextMenu);
    document.removeEventListener('keydown', this.disablePrintAndDownload);
  };

  setDocsArray = (pdfUrl: string) => {
    if(pdfUrl){
      let fileType = "";

    const extension = pdfUrl.split('.').pop();
    if (extension) {
      const extLower = extension.toLowerCase();
      
      if (extLower === 'pdf') {
        fileType = 'pdf';
      } else if (extLower === 'docx') {
        fileType = 'docx';
      } else {
        fileType = 'unknown'; 
      }
    } else {
      fileType = 'unknown'; 
    }

    const docs = [
      {
        uri: pdfUrl,
        fileType: fileType,
      },
    ];
    
    this.setState({docDetails:docs , fileType: fileType})
    }
  };

  handleModifiedPdfUrl = async () => {
    if(this.state.pdfUrl){
      this.setState({
        modifiedPdfUrl: this.state.pdfUrl.replace(/http/g, "https")
      });
    }
  };

  goBack = () => {
    this.props.navigation.goBack();
  };
  disablePrintAndDownload = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && (event.key === 'p' || event.key === 's')) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  disableContextMenu = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    this.setState({ numPages });
  };
  // Customizable Area End
}